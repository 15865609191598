<template>
  <en-dialog :model-value="modelValue" width="80%" title="收款单列表" @close="$emit('update:model-value', false)">
    <table-dynamic
      :height="600"
      code="SPBNSTRTFLD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="store.userTenantId === 'ENOCH' ? table.getByEnoch : table.getByTenant"
      pagination
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      table: {
        ajax: {
          getByTenant: {
            action: 'GET /enospray/statement/paint/business/receipt/query',
            data: 'array',
            loading: true,
            pagination: true
          },
          getByEnoch: {
            action: 'GET /enospray/statement/paint/business/receipt/enoch/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {}
      }
    }
  },

  mounted() {
    this.table[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
  }
})
</script>
